import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../constants'

export const Banner = styled(motion.div)`
    background: var(--color-background);
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media ${device.mobileS} {
        height: 88vh;
    }
    @media ${device.tablet} {
        height: 100vh;
    }
`

export const BannerBackground = styled(motion.div)`
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text);
    font-weight: 900;
    user-select: none;
    grid-column: 1;
    grid-row: 1;
    opacity: 0.04;

    @media ${device.mobileS} {
        font-size: 15vh;
        letter-spacing: 1rem;
    }
    @media ${device.tablet} {
        font-size: 20vh;
        letter-spacing: 3rem;
    }
`

export const BannerQuestion = styled(motion.h2)`
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text);
    font-weight: 800;
    user-select: none;
    grid-column: 1;
    grid-row: 1;
    span {
        color: var(--color-red);
    }

    @media ${device.mobileS} {
        font-size: 3.6rem;
    }
    @media ${device.tablet} {
        font-size: 6.5rem;
    }
`

export const BannerQuestionShadow = styled(motion.h2)`
    text-align: center;
    text-transform: uppercase;
    color: var(--color-textShadow);
    font-weight: 800;
    user-select: none;
    grid-column: 1;
    grid-row: 1;
    mix-blend-mode: overlay;
    opacity: var(--color-textShadowOpacity);
    filter: blur(4px);

    @media ${device.mobileS} {
        font-size: 3.6rem;
    }
    @media ${device.tablet} {
        font-size: 6.5rem;
    }
`

export const BannerRectangleContainer = styled(motion.div)`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-column: 1;
    grid-row: 1;
`

export const BannerRectangle = styled(motion.div)`
    background: ${props => props.color};
    grid-column: 1;
    grid-row: 1;

    @media ${device.mobileS} {
        width: 140px;
        height: 175px;
    }
    @media ${device.tablet} {
        width: 200px;
        height: 250px;
    }
`
export const BannerRectangleShadow = styled(motion.div)`
    background: var(--color-textShadow);
    margin-top: 10px;
    scale: 0.95;
    opacity: var(--color-shadowOpacity);
    grid-column: 1;
    grid-row: 1;
    filter: blur(6px);

    @media ${device.mobileS} {
        width: 140px;
        height: 175px;
    }
    @media ${device.tablet} {
        width: 200px;
        height: 250px;
    }
`

export const MouseAnimation = styled(motion.div)`
    height: 48px;
    width: 28px;
    position: relative;
    /* Same as width */
    border-radius: 28px; 
    border: 3px solid var(--color-text);
    background: transparent;
    text-align: center;
    z-index: 60;
    div {
        display: inline-block;
        width: 4px;
        height: 8px;
        margin-top: 6px;
        background: transparent;
        border-radius: 4px;
        border: 2px solid var(--color-text);
    }

    @media ${device.mobileS} {
        margin-top: 72vh;
        scale: 0.8;
    }
    @media ${device.tablet} {
        margin-top: 80vh;
    }
`

// Quote
export const HomeQuoteSection = styled(motion.div)`
    width: 100%;
    display: block;
    overflow-x: hidden;
    margin-bottom: 200px;
    position: relative;
    margin-top: -10%;
`

export const QuoteContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    padding-top: 10%;
    padding-bottom: 10%;
`

export const QuoteBox = styled(motion.div)`
    position: relative;
    display: inline-block;
    width: 35vw;
    height: 65vh;
    background: var(--color-shade1);
`

export const Quote = styled.h3`
    position: absolute;
    top: 50%;
    transform: translate(0, -60%);
    text-transform: uppercase;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 3rem;
    font-weight: 700;
    color: var(--color-text);
    user-select: none;

    span {
        color: var(--color-red);
    }
`


export const HomeImageSection = styled(motion.div)`
    width: 100%;
    display: grid;
    align-items: top;
    justify-content: center;
    overflow: visible;
`


export const ImageContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
    width: 80vw;
    
    img {
        object-fit: contain;
        @media ${device.mobileS} {
            height: auto;
            width: 100vw;
        }
        @media ${device.tablet} {
            height: 80vh;
            width: auto;
        }
    }
`