import React, { useEffect } from "react"
import HomeBanner from "../components/home/homeBanner"

import { useGlobalDispatchContext } from '../context/globalContext'

// Components
import Layout from "../components/layout"
import HomeImage from "../components/home/homeImage"

const IndexPage = props => {

  const dispatch = useGlobalDispatchContext()

  return (
    <Layout>
      <HomeBanner />
      <HomeImage />
    </Layout>
  )
}

export default IndexPage