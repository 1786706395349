import React from 'react'
import { isMobile } from 'react-device-detect';

import {
    Banner,
    BannerBackground,
    BannerQuestion,
    BannerQuestionShadow,
    BannerRectangleContainer,
} from '../../styles/homeStyles'
import Rectangle from './bannerRectangle'
import RectangleShadow from './bannerRectangleShadow'
import MouseAnimation from './mouseAnimation'

import { useTransform } from 'framer-motion'
import { useWindowDimensions, useRefScroll } from '../../context/hooks'
import ParallaxWrapper from '../parallaxWrapper'
import { constants } from '../../constants'


// Rectangles
const rectangles = [
    {
        scale: 0.65,
        background: 'var(--color-shade4)',
        zIndex: 28,
        parallaxAmount: 3.5 * 70
    },
    {
        scale: 1.05,
        background: 'var(--color-shade3)',
        zIndex: 26,
        parallaxAmount: 3 * 70
    },
    {
        scale: 1.4,
        background: 'var(--color-shade2)',
        zIndex: 24,
        parallaxAmount: 2.5 * 70
    },
    {
        scale: 1.8,
        background: 'var(--color-shade1)',
        zIndex: 22,
        parallaxAmount: 2 * 70
    },
]


const HomeBanner = ({ x, y, scrollY }) => {
    const { height, width } = useWindowDimensions();
    const rotateX = useTransform(y, [0, height], [15, -15]);
    const rotateY = useTransform(x, [0, width], [-15, 15]);
    const moveX = useTransform(x, [0, width], [12, -12]);
    const moveY = useTransform(y, [0, height], [0, -15]);

    const parallaxAmount = 1300 // TODO vw to px
    const { ref, start, end } = useRefScroll()

    const rectContainerVariants = {
        hidden: {},
        show: {
            transition: {
                duration: constants.durationShort,
                ease: constants.customEasing,
                staggerChildren: 0.1,
            },
        },
    }

    const rectVariants = {
        hidden: { y: 72, opacity: 0 },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                duration: constants.durationShort,
                ease: constants.customEasing,
            },
        },
    }

    return (
        <Banner ref={ref}>
            <BannerBackground
                animate={{ opacity: 0.03 }}
                initial={{ opacity: 0 }}
                transition={{ duration: constants.durationShort }}>
                What is it?
            </BannerBackground>
            <BannerRectangleContainer
                variants={rectContainerVariants}
                initial="hidden"
                animate="show"
            >
                {rectangles.map(rectangle => (
                    <ParallaxWrapper
                        scrollY={scrollY}
                        parallaxAmount={rectangle.parallaxAmount}
                        zIndex={rectangle.zIndex}
                        scrollStartOffset={0}
                        scrollEndOffset={end}
                        gridCenter

                        variants={rectVariants}
                    >
                        {isMobile
                            ? <>
                                <Rectangle
                                    rectangle={rectangle}
                                    scrollY={scrollY}
                                />
                                <RectangleShadow
                                    rectangle={rectangle}
                                    scrollY={scrollY} />
                            </>
                            : <>
                                <Rectangle
                                    rotateX={rotateX}
                                    rotateY={rotateY}
                                    rectangle={rectangle}
                                    scrollY={scrollY}
                                />
                                <RectangleShadow
                                    rotateX={rotateX}
                                    rotateY={rotateY}
                                    moveX={moveX}
                                    moveY={moveY}
                                    rectangle={rectangle}
                                    scrollY={scrollY} />
                            </>}

                    </ParallaxWrapper>
                ))}
            </BannerRectangleContainer>
            <ParallaxWrapper
                scrollY={scrollY}
                parallaxAmount={4 * 70}
                zIndex={60}
                scrollStartOffset={0}
                scrollEndOffset={end}
                gridCenter
                overlay

                animate={{ y: 0, opacity: 1 }}
                initial={{ y: -72, opacity: 0 }}
                transition={{ duration: constants.durationShort, ease: constants.customEasing }}
            >
                {isMobile
                    ? <BannerQuestionShadow
                        style={{
                            zIndex: 59,
                        }}>
                        A News App Made<br />For <span> Creators</span>
                    </BannerQuestionShadow>
                    : <BannerQuestionShadow
                        style={{
                            rotateX: rotateX,
                            rotateY: rotateY,
                            translateX: moveX,
                            translateY: moveY,
                            zIndex: 59,
                        }}>
                        A News App Made<br />For <span> Creators</span>
                    </BannerQuestionShadow>
                }
            </ParallaxWrapper>
            {/* Seperate pWrappers to allow for blend mode on shadow */}
            <ParallaxWrapper
                scrollY={scrollY}
                parallaxAmount={4 * 70}
                zIndex={60}
                scrollStartOffset={0}
                scrollEndOffset={end}
                gridCenter

                animate={{ y: 0, opacity: 1 }}
                initial={{ y: -72, opacity: 0 }}
                transition={{ duration: constants.durationShort, ease: constants.customEasing }}
            >
                {isMobile
                    ? <BannerQuestion
                        style={{
                            zIndex: 60,
                        }}>
                        A News App Made<br />For <span> Creators</span>
                    </BannerQuestion>
                    : <BannerQuestion
                        style={{
                            rotateX: rotateX,
                            rotateY: rotateY,
                            zIndex: 60,
                        }}>
                        A News App Made<br />For <span> Creators</span>
                    </BannerQuestion>
                }
            </ParallaxWrapper>
            <ParallaxWrapper
                scrollY={scrollY}
                parallaxAmount={4 * 70}
                zIndex={60}
                scrollStartOffset={start}
                scrollEndOffset={end}
                gridCenter

                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 72, opacity: 0 }}
                transition={{ duration: constants.durationShort, ease: constants.customEasing }}
            >
                <MouseAnimation
                    scrollY={scrollY} />
            </ParallaxWrapper>
        </Banner >
    )
}

export default HomeBanner
