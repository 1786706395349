import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../constants'

export const HeaderNav = styled(motion.div)`
    height: 0px;
    width: 100%;
    position: absolute;
    
    right: 0;
    left: 0;
    z-index: 99;

    @media ${device.mobileS} {
        top: 36px;
    }
    @media ${device.tablet} {
        top: 48px;
    }
`

export const Logo = styled.div`
    svg {
        pointer-events: all;
        path {
            pointer-events: all;
            &.square {
                fill: var(--color-text) !important;
            }
        }

        @media ${device.mobileS} {
            height: 3.8rem;
            width: 3.8rem;
        }
        @media ${device.tablet} {
            height: 4rem;
            width: 4rem;
        }
    }
    a {
        font-size: 1.8rem;
        font-weight: 800;
        color: var(--color-text);
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        margin-left: 1rem;
        pointer-events: all;

        @media ${device.mobileS} {
            display: none;
        }
        @media ${device.tablet} {
            display: unset;
        }
    }
`

export const Menu = styled.div`
    button {
        transform-origin: center;
        border: none;
        padding: 20px;
        background: none;
        outline: none;
        span {
            display: block;
            background: var(--color-text);
            
            @media ${device.mobileS} {
                width: 32px;
                height: 7px;
                margin: 7px;
            }
            @media ${device.tablet} {
                width: 36px;
                height: 8px;
                margin: 8px;
            }
        }
    }
`


export const SignUpButton = styled(motion.div)`
    button {
        transform-origin: center;
        border: none;
        background: none;
        padding: 12px;
        border-radius: 28px; 
        border: 3px solid var(--color-text);
    }
`

export const SignUpButtonText = styled(motion.text)`
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text);
    font-size: 1rem;
    font-weight: 700;
    user-select: none;
    grid-column: 1;
    grid-row: 1;
    span {
        color: ${props => props.theme.red};
    }
`