import React from "react"

export const CultureComicLogo = ({ onCursor, toggleTheme }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="150px" viewBox="0 0 2000 2000">
        <path
            d="M146.17 1242.42c-36.07 -132.43 -44.98 -258.66 -26.76 -378.67c18.24 -120.02 58.12 -229.05 119.67 -327.11C300.64 438.6 381.61 354.22 482 283.5c100.39 -70.71 213.92 -122.76 340.6 -156.16c129.85 -34.24 258.19 -45.94 385.02 -35.13c126.84 10.81 238.6 41.04 335.29 90.7l-234.77 502.71c-42.7 -32.16 -90.66 -52.48 -143.9 -61c-53.23 -8.51 -109.14 -5.04 -167.72 10.41c-47.51 12.53 -89.62 32.39 -126.33 59.6c-36.71 27.22 -66.27 60.47 -88.66 99.77c-22.39 39.3 -36.25 83.44 -41.57 132.44c-5.33 48.99 -0.56 100.75 14.28 155.28c14.85 54.53 37.22 100.82 67.09 138.86s64.49 68.57 103.86 91.59c39.37 23.02 81.86 37.28 127.49 42.78c45.63 5.51 91.4 2.2 137.32 -9.9c64.92 -17.12 117.14 -45.07 156.63 -83.87c39.5 -38.8 67.84 -79.67 84.99 -122.59l458.16 317.53c-55.65 94.82 -132.84 179.04 -231.59 252.67c-98.74 73.62 -218.59 129.01 -359.51 166.16c-126.68 33.39 -251.56 43.77 -374.65 31.14c-123.09 -12.63 -236.31 -46.65 -339.66 -102.06c-103.34 -55.41 -193.29 -130.22 -269.86 -224.41C237.92 1485.81 181.81 1373.29 146.17 1242.42L146.17 1242.42z"
            fill="#FF2345"
        ></path>
        <path
            className="square"
            d="M1239.72 843.4L921.37 843.4 921.37 1156.6 1239.72 1156.6 1239.72 843.4Z"
            fill="#000000"
            fillRule="evenodd"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
            onClick={toggleTheme}
        ></path>
    </svg>
)

export default CultureComicLogo
