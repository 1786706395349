
import React from 'react'
import { HomeImageSection, BannerBackground, ImageContainer } from '../../styles/homeStyles'
import ParallaxWrapper from '../parallaxWrapper'
import { constants } from '../../constants'
import { useRefScroll } from '../../context/hooks'

const HomeImage = ({ scrollY }) => {
    const { ref, start, end } = useRefScroll()

    return (
        <HomeImageSection ref={ref}>
            <BannerBackground
                animate={{ opacity: 0.03 }}
                initial={{ opacity: 0 }}
                transition={{ duration: constants.durationShort }}>
                The App
            </BannerBackground>
            <ParallaxWrapper
                scrollY={scrollY}
                parallaxAmount={7 * 70}
                zIndex={60}
                scrollStartOffset={0}
                scrollEndOffset={end}
                gridCenter

                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 72, opacity: 0 }}
                transition={{ duration: constants.durationShort, ease: constants.customEasing }}>
                <ImageContainer>
                    <img src={require('../../assets/img/mockup.png').default} />
                </ImageContainer>
            </ParallaxWrapper>
        </HomeImageSection>


    )
}

export default HomeImage