// Created this component so I can use an iterable hook lol
import React from 'react'
import { useTransform } from 'framer-motion'

import { BannerRectangleShadow } from '../../styles/homeStyles'

export const RectangleShadow = ({ rotateX, rotateY, moveX, moveY, rectangle, scrollY }) => {
    const y = useTransform(scrollY, [0, 300], [0, -70 * rectangle.parallaxSpeed])

    return (
        <BannerRectangleShadow
            style={{
                scale: rectangle.scale,
                rotateX: rotateX,
                rotateY: rotateY,
                translateX: moveX,
                translateY: moveY,
                zIndex: rectangle.zIndex - 1,
            }}
        />
    )
}

export default RectangleShadow
