// Created this component so I can use an iterable hook lol
import React from 'react'
import { useTransform } from 'framer-motion'

import { BannerRectangle } from '../../styles/homeStyles'

export const Rectangle = ({ rotateX, rotateY, rectangle, scrollY }) => {
    const y = useTransform(scrollY, [0, 300], [0, -70 * rectangle.parallaxSpeed])

    return (
        <BannerRectangle
            style={{
                rotateX: rotateX,
                rotateY: rotateY,
                scale: rectangle.scale,
                zIndex: rectangle.zIndex,
            }}
            color={rectangle.background}
        />
    )
}

export default Rectangle
