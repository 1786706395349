import { useState, useEffect, useRef, useLayoutEffect } from 'react';

function getWindowDimensions() {

    const { innerWidth: width, innerHeight: height } =
        typeof window !== "undefined"
            ? window
            : { width: 0, height: 0 };
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function useRefScroll() {
    const ref = useRef();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }
        const rect = ref.current.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offsetTop = rect.top + scrollTop;
        setStart(offsetTop);
        setEnd(offsetTop + rect.height);
    });
    return { ref, start, end };
}