import React from 'react'
import { MouseAnimation } from '../../styles/homeStyles'
import { motion, useTransform } from 'framer-motion'

const MouseAnim = ({ scrollY }) => {
    const opacity = useTransform(scrollY, [0, 150], [1, 0])

    return (
        <MouseAnimation
            animate={{ y: 10 }}
            transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: "reverse",
            }}
            style={{
                opacity: opacity,
            }}
        >
            <motion.div
                animate={{ y: 6 }}
                transition={{
                    duration: 0.8,
                    repeat: Infinity,
                    repeatType: "reverse",
                }}
            />
        </MouseAnimation>
    )
}

export default MouseAnim
