import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../constants'

export const Container = styled.div`
    flex-grow: 1;
    margin: 0 auto;
    padding: 0 36px;
    position: relative;
    width: auto;
    height: 100%;

    /* max width = device - 64 */
    @media ${device.tablet} {
        max-width: 704px;
    }
    @media ${device.laptop} {
        max-width: 960px;
    }
    @media ${device.laptopL} {
        max-width: 1376px;
    }
    @media ${device.desktop} {
        max-width: 2450px;
    }

    ${props => props.fluid &&
        css`
        padding: 0;
        margin: 0;
        max-width: 100%;
    `}
`

export const ParallaxDiv = styled(motion.div)`
    ${props => props.gridCenter &&
        css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-column: 1;
        grid-row: 1;
    `}
    ${props => props.overlay &&
        css`
        mix-blend-mode: overlay;
    `}
`

export const Flex = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    ${props => props.spaceBetween &&
        css`
        justify-content: space-between;
    `}

    ${props => props.flexEnd &&
        css`
        justify-content: flex-end;
    `}

    ${props => props.alignTop &&
        css`
        justify-content: flex-start;
        align-items: flex-start;
    `}

    ${props => props.noHeight &&
        css`
        height: 0;
    `}
`

export const Cursor = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background: var(--color-red);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;
    transition-property: width, height, border;
    will-change: width, height, transform, border;
    pointer-events: none;
    z-index: 999;
    &.alternate {
        background: var(--color-text) !important;
    }
    &.pointer {
        border: 4px solid var(--color-text) !important;
        background: transparent !important;
        width: 48px;
        height: 48px;
        border: 4px solid var(--color-red);
    }
    &.hovered {
        background: transparent !important;
        width: 48px;
        height: 48px;
        border: 4px solid var(--color-red);
    }
    &.locked {
        background: transparent !important;
        width: 48px;
        height: 48px;
        border: 4px solid var(--color-red);
        top: ${props => props.theme.top} !important;
        left: ${props => props.theme.left} !important;
    }
    &.hidden {
        opacity: 0;
    }
    &.visible {
        opacity: 1;
    }
`