import { Link } from 'gatsby'
import React, { useEffect } from 'react'

import { Container, Flex } from '../styles/globalStyles'
import { HeaderNav, Logo, SignUpButton, SignUpButtonText } from '../styles/headerStyles'
import { CultureComicLogo } from '../assets/svg/logo'

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'
import { THEME_MODE_KEY, COLORS, constants } from '../constants'

const Header = ({ onCursor }) => {
    const dispatch = useGlobalDispatchContext()
    const { currentTheme } = useGlobalStateContext()

    const toggleTheme = () => {
        if (currentTheme === 'dark') {
            dispatch({
                type: 'TOGGLE_THEME',
                theme: 'light'
            })
        } else {
            dispatch({
                type: 'TOGGLE_THEME',
                theme: 'dark'
            })
        }
    }

    useEffect(() => {
        // Change colors
        const root = window.document.documentElement;
        Object.entries(COLORS).forEach(([name, colorByTheme]) => {
            const cssVarName = `--color-${name}`;
            root.style.setProperty(cssVarName, colorByTheme[currentTheme]);
        });

        // Save changes
        window.localStorage.setItem(THEME_MODE_KEY, currentTheme)
    }, [currentTheme])

    return (
        <HeaderNav
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: -72, opacity: 0 }}
            transition={{ duration: constants.durationShort, ease: constants.customEasing }}
            onMouseEnter={onCursor}>
            <Container>
                <Flex spaceBetween>
                    <Logo>
                        <CultureComicLogo
                            onCursor={onCursor}
                            toggleTheme={toggleTheme} />
                        <Link
                            to='/'
                            onMouseEnter={() => onCursor('hovered')}
                            onMouseLeave={onCursor}
                        >Culture Comic</Link>
                    </Logo>
                    <SignUpButton>
                        <button
                            onClick={() => window.open("https://forms.gle/3Y4qPjPEHCQio85z8", "_blank")}
                            onMouseEnter={() => onCursor('hovered')}
                            onMouseLeave={onCursor}>
                            <SignUpButtonText>Early Access &gt;&gt;</SignUpButtonText>
                        </button>
                    </SignUpButton>
                </Flex>
            </Container>
        </HeaderNav>
    )
}

export default Header
