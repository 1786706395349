import React from 'react'
import PropTypes from "prop-types"
import { useMotionValue, useViewportScroll } from 'framer-motion'
import { isMobile } from 'react-device-detect';

// styled components
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

// components
import Cursor from './customCursor'
import Header from './header'

// context
import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
      text-decoration: none;
      cursor: none;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font: 16px;
  }

  body{
    font-family: roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: var(--color-background);
    overscroll-behavior: none;
    overflow-x: hidden;
  }

`

const Layout = ({ children }) => {

    // Cursor hover and theme
    const { cursorStyles } = useGlobalStateContext()
    const dispatch = useGlobalDispatchContext()

    const onCursor = cursorType => {
        cursorType = (cursorStyles.includes(cursorType) && cursorType) || false
        dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
    }

    // Scroll
    const { scrollY } = useViewportScroll();

    // Cursor move
    const x = useMotionValue(0);
    const y = useMotionValue(0);

    function handleMouse(event) {
        const rect = event.currentTarget.getBoundingClientRect();

        x.set(event.clientX - rect.left);
        y.set(event.clientY - rect.top);
    }

    // Nested props
    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { onCursor, x, y, scrollY });
        }
        return child;
    });
    return (
        <>
            <GlobalStyle />
            {!isMobile && <Cursor />}
            <main
                onMouseMove={handleMouse}
                onMouseEnter={onCursor}
                onMouseLeave={() => onCursor('hidden')}
            >
                <Header onCursor={onCursor} />
                {childrenWithProps}
            </main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
