import React from 'react'
import PropTypes from "prop-types"

import { useTransform } from 'framer-motion'
import { ParallaxDiv } from '../styles/globalStyles'

const ParallaxWrapper = ({ scrollY, parallaxAmount, children, zIndex, gridCenter, overlay, horizontal, scrollStartOffset, scrollEndOffset, reverse, style, stopPercentage, animate, initial, transition, variants }) => {
    var scrollEnd
    if (stopPercentage) {
        scrollEnd = (scrollEndOffset - scrollStartOffset) * stopPercentage + scrollStartOffset

    } else {
        scrollEnd = scrollEndOffset
    }
    const y = useTransform(scrollY, [scrollStartOffset, scrollEnd], [0, reverse ? parallaxAmount : - parallaxAmount])
    const x = useTransform(scrollY, [scrollStartOffset, scrollEnd], [0, reverse ? parallaxAmount : - parallaxAmount])

    var newStyle = {}
    if (horizontal) {
        newStyle = {
            translateX: x,
            zIndex: zIndex,
        }
    } else {
        newStyle = {
            translateY: y,
            zIndex: zIndex,
        }
    }
    const finalStyle = Object.assign({}, style, newStyle);
    return (
        <ParallaxDiv
            style={finalStyle}
            gridCenter={gridCenter}
            overlay={overlay}

            animate={animate}
            initial={initial}
            transition={transition}
            variants={variants}
        >
            {children}
        </ParallaxDiv>
    )

}

ParallaxWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ParallaxWrapper
